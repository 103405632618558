import React, { useCallback, useEffect, useRef, useState } from 'react';

import dagre from 'dagre';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import { AllRecruitsResultsList, ResultsList, UserDetails } from 'managers/Users.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import { Bar } from 'react-chartjs-2';
import Card from 'classes/Card.js';
import { CardDetails, getTotalUnitsBadge } from 'managers/Cards.js';
import Content from 'managers/Content.js';
import Dealership from 'classes/Dealership.js';
import { DealershipDetails } from 'managers/Dealerships.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import IncentivesTrackingReport from 'classes/IncentivesTrackingReport.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import ListField from 'views/ListField.js';
import MonthYearPicker from 'views/MonthYearPicker.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import ReactFlow, { ReactFlowProvider, addEdge, removeElements, isNode } from 'react-flow-renderer';
import Request from 'files/Request.js';
import Sector from 'classes/Sector.js';
import { SectorDetails } from 'managers/Sectors.js';
import { StatefulSlider } from 'views/Slider.js';
import { TableListHeader, TableListRow } from 'views/TableList.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const AddEditIncentivesTrackingReport = ({ allDealerships, isNewTarget, onUpdate }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_incentives_tracking_report' : `edit_incentives_tracking_report_${abstract.getID()}`;
    const [edits, setEdits] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onAddNewTarget = async () => {
        try {
            let next = await abstract.object.set({
                ...edits,
                preferences: {
                    ...edits.preferences,
                    targets: edits.preferences.targets.concat([{
                        name: 'Untitled',
                        value: 1
                    }])
                }
            });
            setEdits(next);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding a target. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onMoveTarget = async (index, val) => {
        try {
            let target = edits.preferences.targets[index];
            let next = await abstract.object.set({
                ...edits,
                preferences: {
                    ...edits.preferences,
                    targets: update(edits.preferences.targets, {
                        $splice: [[ index, 1 ], [ index + val, 0, target ]]
                    })
                }
            });
            setEdits(next);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue moving this target. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onRemoveTarget = index => {
        if(index === 0) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'You can not delete this target. At least one target is required for each incentives tracking report.'
            });
            return;
        }
        utils.alert.show({
            title: 'Remove Target',
            message: `Are you sure that you want to remove this target?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveTargetConfirm(index);
                    return;
                }
            }
        })
    }

    const onRemoveTargetConfirm = async index => {
        try {
            let next = await abstract.object.set({
                ...edits,
                preferences: {
                    ...edits.preferences,
                    targets: edits.preferences.targets.filter((target, i) => i !== index)
                }
            });
            setEdits(next);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this target. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmit = async () => {
        try {

            // verify that all required fields have been filled out
            await validateRequiredFields(getFields);

            // verify that at least one target was provided
            if(!edits.preferences.targets || edits.preferences.targets.length === 0) {
                throw new Error('At least one target is required for each incentives tracking report');
            }

            // commit changes to target
            setLoading(true);
            await Utils.sleep(0.5);
            await abstract.object.apply(utils, isNewTarget);

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The ${edits.name} incentives tracking report has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: setLayerState.bind(this, 'close')
            });

            // notify subscribers of data change
            if(typeof(onUpdate) === 'function') {
                onUpdate(abstract.object);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} your incentives tracking report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = async props => {
        try {
            let edits = await abstract.object.set(props);
            setEdits(edits);
        } catch(e) {
            console.error(e.message);
        }
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'add_target',
            onClick: onAddNewTarget,
            text: 'Add a Milestone'
        },{
            color: 'primary',
            key: 'confirm',
            onClick: onSubmit,
            text: isNewTarget ? 'Submit' : 'Save Changes'
        }];
    }

    const getFields = () => {
        return [{
            key: 'configuration',
            lastItem: false,
            title: 'Configuration',
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The incentives tracking report name will be shown when interacting with this trip in the reports section of AFT.',
                component: 'textfield',
                value: edits.name,
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'start_date',
                title: 'Start Date',
                description: 'This date should be the first day that sales are accepted when determining incentives tracking report eligibility.',
                component: 'date_picker',
                value: edits.preferences && edits.preferences.start_date,
                onChange: date => {
                    onUpdateTarget({
                        preferences: {
                            ...edits.preferences,
                            start_date: date && date.format('YYYY-MM-DD')
                        }
                    })
                }
            },{
                key: 'end_date',
                title: 'End Date',
                description: 'This date should be the last day that sales are accepted when determining incentives tracking report eligibility.',
                component: 'date_picker',
                value: edits.preferences && edits.preferences.end_date,
                onChange: date => {
                    onUpdateTarget({
                        preferences: {
                            ...edits.preferences,
                            end_date: date && date.format('YYYY-MM-DD')
                        }
                    })
                }
            },{
                key: 'levels',
                required: false,
                title: 'Account Types',
                description: 'If needed, you can declare the types of account that are eligible for this incentives tracking report.',
                component: 'multiple_list',
                items: getLevelItems(),
                value: getLevelValues(),
                onChange: items => {
                    onUpdateTarget({
                        preferences: {
                            ...edits.preferences,
                            levels: items && items.map(item => item.id)
                        }
                    })
                }
            },{
                key: 'multiplier',
                title: 'Recruiting Multiplier',
                description: 'This multiplier is used when calculating the overall score for trip eligibility. The score is calculated using NUMBER OF UNITS SOLD + (NUMBER OF RECRUITS x MULTIPLIER).',
                component: 'textfield',
                value: edits.preferences && edits.preferences.multiplier,
                props: { format: 'number' },
                onChange: val => {
                    onUpdateTarget({
                        preferences: {
                            ...edits.preferences,
                            multiplier: val && parseFloat(val) || 1
                        }
                    })
                }
            }]
        }]
    }

    const getLevelItems = () => {
        return Object.values(User.levels.get()).filter(key => {
            return utils.user.get().level <= key;
        }).map(key => ({
            id: key,
            title: User.levels.toText(key)
        }));
    }

    const getLevelValues = () => {
        let levels = edits.preferences && edits.preferences.levels || [];
        return levels.map(code => ({
            id: code,
            title: User.levels.toText(code)
        }))
    }

    const getTargets = () => {

        let targets = edits.preferences && edits.preferences.targets || [];
        return targets.map((target, index, targets) => {
            return (
                <AltFieldMapper
                key={index}
                utils={utils}
                fields={[{
                    collapsed: null,
                    key: `target_${index}`,
                    lastItem: index === targets.length - 1,
                    title: target.name || 'Untitled Milestone',
                    rightContent: (
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            {targets.length > 1 && (
                                <>
                                <img
                                className={'text-button'}
                                onClick={onMoveTarget.bind(this, index, -1)}
                                src={'images/up-arrow-grey-small.png'}
                                title={'Move Up'}
                                style={{
                                    height: 18,
                                    marginRight: 4,
                                    objectFit: 'contain',
                                    width: 18
                                }} />
                                <img
                                className={'text-button'}
                                onClick={onMoveTarget.bind(this, index, 1)}
                                src={'images/down-arrow-grey-small.png'}
                                title={'Move Down'}
                                style={{
                                    height: 18,
                                    marginRight: 8,
                                    objectFit: 'contain',
                                    width: 18
                                }} />
                                </>
                            )}
                            <AltBadge
                            onClick={onRemoveTarget.bind(this, index)}
                            style={{ marginRight: 0 }}
                            content={{
                                color: Appearance.colors.red,
                                text: 'Remove'
                            }} />
                        </div>
                    ),
                    items: [{
                        key: 'name',
                        title: 'Name',
                        description: 'The name represents the milestone that has been reached during the trip qualification journey.',
                        component: 'textfield',
                        value: target.name,
                        onChange: val => {
                            onUpdateTarget({
                                preferences: {
                                    ...edits.preferences,
                                    targets: update(edits.preferences.targets || {}, {
                                        [index]: {
                                            name: {
                                                $set: val
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    },{
                        key: 'value',
                        title: 'Score',
                        description: 'The target value represents the minimum score required to become eligible for this target. The score is calculated using NUMBER OF UNITS SOLD + (NUMBER OF RECRUITS x MULTIPLIER)',
                        component: 'textfield',
                        value: target.value,
                        props: { format: 'number' },
                        onChange: val => {
                            onUpdateTarget({
                                preferences: {
                                    ...edits.preferences,
                                    targets: update(edits.preferences.targets || {}, {
                                        [index]: {
                                            value: {
                                                $set: val
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    }]
                }]} />
            )
        });
    }

    const setupTarget = async () => {
        try {

            // open editing for target
            let edits = await abstract.object.open();
            if(isNewTarget) {

                // set dealeship if user is not an administrator or if allDealerships is false
                if(allDealerships === false || utils.user.get().level > User.levels.get().admin) {
                    edits.target_dealership_id = utils.dealership.get().id;
                }

                // create default preferences object
                edits.preferences = {
                    multiplier: 100,
                    targets: [{
                        name: null,
                        value: 1
                    }]
                }

                // set edits for target
                await abstract.object.set(edits);
            }

            // update local state with edits
            setEdits(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the ${isNewTarget ? 'creation' : 'editing'} process. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <div className={'custom-scrollbars'}>
                <AltFieldMapper
                fields={getFields()}
                utils={utils} />
                {getTargets()}
            </div>
        </Layer>
    )
}

export const getValueContainer = (title, color, onClick) => {

    let _color = color || Appearance.colors.primary();
    return (
        <div
        onClick={onClick}
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            maxWidth: 95,
            textAlign: 'center',
            border: `1px solid ${_color}`,
            background: Appearance.colors.softGradient(_color),
            borderRadius: 5,
            overflow: 'hidden'
        }}>
            <span style={{
                ...Appearance.textStyles.subTitle(),
                color: 'white',
                fontWeight: '600',
                width: '100%'
            }}>{isNaN(title) ? title : Utils.softNumberFormat(title)}</span>
        </div>
    )
}

export const GlobalDataLeadPoolBreakdown = ({ index, options, utils }) => {

    const panelID = 'global_data_lead_pool_breakdown';
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef({ sort_key: 'dealership_name', sort_type: Content.sorting.type.ascending });

    const [historicValue, setHistoricValue] = useState(null);
    const [historicValues, setHistoricValues] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [loading, setLoading] = useState('init');
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);
    const [totals, setTotals] = useState([]);

    const onDealershipClick = async id => {
        try {
            let dealership = await Dealership.get(utils, id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: dealership,
                    type: 'dealership'
                }),
                Component: DealershipDetails,
                id: `dealership_details_${dealership.id}`
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this dealership. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSortingChange = val => {
        setLoading(true);
        sorting.current = val;
        fetchResults();
    }

    const getContent = () => {
        if(loading === 'init') {
            return null;
        }
        if(results.length === 0) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        subTitle: 'No global data lead pool breakdowns were found',
                        title: 'Nothing to see here'
                    })}
                </div>
            )
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1
                }}>
                    <table
                    className={'px-3 py-2 m-0'}
                    style={{
                        width: '100%'
                    }}>
                        <thead style={{
                            width: '100%'
                        }}>
                            {getFields()}
                        </thead>
                        <tbody style={{
                            width: '100%'
                        }}>
                            {results.map(getFields)}
                        </tbody>
                    </table>
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset.current}
                        onClick={next => {
                            setLoading(true);
                            offset.current = next;
                            fetchResults();
                        }}/>
                    )}
                </div>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 12,
                    minWidth: 350
                }}>
                    {totals.map((entry, index) => {
                        return (
                            Views.row({
                                bottomBorder: index !== totals.length - 1,
                                key: index,
                                label: entry.title,
                                value: entry.key === 'overage_fee' ? Utils.toCurrency(entry.value) : Utils.softNumberFormat(entry.value),
                                ...['overage', 'overage_fee'].includes(entry.key) && {
                                    color: entry.key === 'overage_fee' ? Appearance.colors.green : Appearance.colors.red
                                }
                            })
                        )
                    })}
                </div>
            </div>
        )
    }

    const getFields = (result, index) => {
    
        // prepare default row items
        let target = result || {};
        let fields = [{
            key: 'dealership_name',
            title: 'Dealership',
            value: target.dealership_name
        },{
            key: 'subscription',
            title: 'Subscription',
            value: target.subscription
        },{
            key: 'lead_cap',
            title: 'Lead Cap',
            value: target.lead_cap && Utils.softNumberFormat(target.lead_cap)
        },{
            key: 'unarchived',
            title: 'Active Leads',
            value: target.unarchived && Utils.softNumberFormat(target.unarchived)
        },{
            key: 'archived',
            title: 'Archived Leads',
            value: target.archived && Utils.softNumberFormat(target.archived)
        },{
            key: 'total',
            title: 'Total Leads',
            value: target.total && Utils.softNumberFormat(target.total)
        },{
            color: target.overage > 0 && Appearance.colors.red,
            key: 'overage',
            title: 'Overage',
            value: target.overage && Utils.softNumberFormat(target.overage)
        },{
            color: target.overage_fee > 0 && Appearance.colors.green,
            key: 'overage_fee',
            title: 'Overage Fee',
            value: target.overage_fee && Utils.toCurrency(target.overage_fee)
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!result) {
            return (
                <TableListHeader
                fields={fields}
                onChange={onSortingChange}
                value={sorting.current} />
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === results.length - 1}
            onClick={onDealershipClick.bind(this, target && target.dealership_id)} />
        )
    }

    const getHistoricValues = () => {
        return historicValues.length > 0 && (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 12
            }}>
                <ListField
                items={historicValues}
                onChange={item => {
                    setLoading(true);
                    setHistoricValue(item && item.id);
                }}
                placeholder={'Select a date from the list...'}
                value={historicValue && historicValues.find(value => value.id === historicValue).title}
                style={{
                    width: 250
                }}/>
            </div>
        )
    }

    const fetchResults = async () => {
        try {
            console.log(historicValue)
            let { historic_values, last_updated, paging, results, totals } = await Request.get(utils, '/reports/', {
                date: historicValue,
                limit: limit,
                offset: offset.current,
                type: 'global_data_lead_pool_breakdown',
                ...sorting.current
            });

            setLoading(false);
            setLastUpdated(moment().utc(last_updated).local());
            setPaging(paging);
            setResults(results);
            setTotals(totals);

            // prepare list items for historic values list field
            setHistoricValues(historic_values.map(date => {
                return {
                    id: date,
                    title: moment(`${date}-01`).format('MMMM YYYY')
                }
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the global data lead pool breakdown report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchResults();
    }, [historicValue]);

    return (
        <Panel
        panelID={panelID}
        name={'Global Data Lead Pool Breakdown'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            subTitle: lastUpdated ? `Totals ${lastUpdated.format(historicValue ? '[Archived:] MMMM Do' : '[Updated: Today at] h:mma')}` : 'Awaiting breakdown data...'
        }}>
            {getHistoricValues()}
            {getContent()}
        </Panel>
    )
}

export const ReportsLayer = ({ category, onChange }, { index, options, utils }) => {

    const layerID = 'reports';
    const [items, setItems] = useState([]);
    const [layerState, setLayerState] = useState(null);

    const onDataChange = results => {
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange(results);
        }
    }

    const getContent = () => {
        return items.map(({ Component }, index) => (
            <Component
            index={index}
            key={index}
            utils={utils} />
        ));
    }

    const getItems = () => {

        // determine if dealership categories were requested
        if(category === 'dealerships') {
            return [{
                key: 'dealership_sales',
                title: 'Dealership Sales',
                Component: ReportPanel.bind(this, {
                    id: 'dealership_sales',
                    default_range: 'month',
                    embeded: true,
                    onDataChange: onDataChange
                })
            },{
                key: 'director_inside_office',
                title: 'Director Inside Office',
                Component: ReportPanel.bind(this, {
                    id: 'director_inside_office',
                    default_range: 'month',
                    embeded: true,
                    onDataChange: onDataChange
                })
            },{
                key: 'top_recruiting_offices',
                title: 'Top Recruiting Offices',
                Component: ReportPanel.bind(this, {
                    id: 'top_recruiting_offices',
                    default_range: 'month',
                    embeded: true,
                    onDataChange: onDataChange
                })
            }];
        }

        // determine if division categories were requested
        if(category === 'divisions') {
            return [{
                key: 'division_team_sales',
                title: 'Division Team Sales',
                Component: ReportPanel.bind(this, {
                    id: 'division_team_sales',
                    sector: 'Division',
                    default_range: 'month',
                    embeded: true,
                    onDataChange: onDataChange
                })
            }];
        }

        // determine if region categories were requested
        if(category === 'regions') {
            return [{
                key: 'region_team_sales',
                title: 'Region Team Sales',
                Component: ReportPanel.bind(this, {
                    id: 'region_team_sales',
                    sector: 'Region',
                    default_range: 'month',
                    embeded: true,
                    onDataChange: onDataChange
                })
            }];
        }

        // fallback to user categories
        return [{
            key: 'dealer_personal_sales',
            title: 'Dealer Personal Sales',
            Component: ReportPanel.bind(this, {
                id: 'dealer_personal_sales',
                category: 'dealer',
                default_range: 'month',
                embeded: true,
                onDataChange: onDataChange
            })
        },{
            key: 'safety_advisor_personal_sales',
            title: 'Safety Advisor Personal Sales',
            Component: ReportPanel.bind(this, {
                id: 'safety_advisor_personal_sales',
                category: 'safety_advisor',
                default_range: 'month',
                embeded: true,
                onDataChange: onDataChange
            })
        },{
            key: 'new_recruits',
            title: 'New Recruits',
            Component: ReportPanel.bind(this, {
                id: 'new_recruits',
                default_range: 'month',
                embeded: true,
                onDataChange: onDataChange
            })
        },{
            key: 'new_recruits_with_dealers',
            title: 'New Recruits (Dealers Included)',
            Component: ReportPanel.bind(this, {
                id: 'new_recruits_with_dealers',
                embeded: true,
                onDataChange: onDataChange
            })
        },{
            key: 'thirty_for_thirty',
            title: '30 for 30 Demos',
            Component: ReportPanel.bind(this, {
                id: 'thirty_for_thirty',
                embeded: true,
                onDataChange: onDataChange
            })
        }];
    }

    useEffect(() => {
        setItems(getItems());
    }, []);

    return (
        <Layer 
        id={layerID}
        index={index}
        title={'Reports'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            removePadding: true,
            sizing: 'extra_large'
        }}>
            <div className={'pb-3'}>
                {getContent()}
            </div>
        </Layer>
    )
}

export const ReportPanel = ({ default_range, id, embeded, onDataChange, sector }, { index, options, utils }) => {

    const panelID = id;
    const limit = 10;

    const graphProps = useRef({ visible: false, limit: 25 });
    const endDate = useRef(null);
    const offset = useRef(0);
    const oneToOne = useRef(1);
    const showInactiveUsers = useRef(true);
    const startDate = useRef(null);

    const [graphData, setGraphData] = useState(null);
    const [loading, setLoading] = useState(null);
    const [paging, setPaging] = useState(null);
    const [incentivesTrackingReport, setIncentivesTrackingReport] = useState(null);
    const [incentivesTrackingReports, setIncentivesTrackingReports] = useState([]);
    const [results, setResults] = useState([]);
    const [totals, setTotals] = useState(null);

    const onAllRecruitsClick = user => {
        let layerID = `all_recruits_${user.user_id}_${moment()}`;
        utils.layer.open({
            id: layerID,
            Component: AllRecruitsResultsList.bind(this, {
                layerID: layerID,
                title: `All Recruits for ${user.first_name} ${user.last_name}`,
                owner: user,
                users: user.recruits,
                onRenderUser: (user, index, users) => {
                    return (
                        Views.entry({
                            key: index,
                            title: `${user.first_name} ${user.last_name}`,
                            subTitle: user.recruit_date ? `Recruited ${moment(user.recruit_date).format('MMMM Do, YYYY')}` : 'No sales have been submitted',
                            icon: {
                                path: user.avatar
                            },
                            singleItem: users.length === 1,
                            firstItem: index === 0,
                            lastItem: index === users.length - 1,
                            bottomBorder: false,
                            onClick: onUserClick.bind(this, user.user_id),
                            rightContent: getValueContainer(`${user.recruit_count} ${user.recruit_count === 1 ? 'Recruit' : 'Recruits'}`, user.recruit_count > 0 ? Appearance.colors.green : Appearance.colors.grey())
                        })
                    )
                }
            })
        });
    }

    const onBreakdownClick = async (props, evt) => {
        try {

            // stop propagation if applicable
            // mouse event may not be supplied
            if(evt) {
                evt.stopPropagation();
            }

            setLoading(true);
            let next = {
                ...props,
                limit: limit,
                offset: 0
            };

            let { paging, results } = await fetchBreakdown(next);
            setLoading(false);
            onShowBreakdownResults({
                ...next,
                paging: paging,
                results: results
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue performing your request. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCardClick = async card => {
        utils.layer.open({
            id: `card_details_${card.id}`,
            abstract: Abstract.create({
                type: 'card',
                object: card
            }),
            Component: CardDetails
        });
    }

    const onDealershipClick = async id => {
        try {
            let dealership = await Dealership.get(utils, id);
            utils.layer.open({
                id: `dealership_details_${dealership.id}`,
                abstract: Abstract.create({
                    type: 'dealership',
                    object: dealership
                }),
                Component: DealershipDetails
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDealershipChange = () => {

        // set request to fetch report data
        onRequestNewReport();

        // fetch list of incentives tracking report reports and reset currently selected trip if panel is meant for incentives tracking reports
        if(panelID === 'incentives_tracking') {
            fetchIncentivesTrackingReports();
        }
    }

    const onEditIncentivesTrackingReportClick = evt => {
        
        // stop event from propagating if an event was provided
        if(evt && typeof(evt.stopPropagation) === 'function') {
            evt.stopPropagation();
        }
        
        // show layer details for incentives tracking report report
        utils.layer.open({
            id: `edit_incentives_tracking_report_${incentivesTrackingReport.id}`,
            abstract: Abstract.create({
                object: incentivesTrackingReport,
                type: 'incentives_tracking_report'
            }),
            Component: AddEditIncentivesTrackingReport.bind(this, {
                isNewTarget: false,
                onUpdate: result => {

                    // update incentives tracking report report
                    setIncentivesTrackingReport(trip => trip.id === incentivesTrackingReport.id ? result : trip);

                    // update list of incentives tracking report reports
                    setIncentivesTrackingReports(trips => {
                        return trips.map(entry => {
                            return entry.id === result.id ? result : entry;
                        }).sort((a,b) => {
                            return a.name.localeCompare(b.name);
                        });
                    });

                    // request new report data
                    onRequestNewReport();
                }
            })
        });
    }

    const onIncentivesTrackingReportChange = item => {
        setResults([]);
        setPaging(null);
        setIncentivesTrackingReport(item && incentivesTrackingReports.find(trip => trip.id === item.id))
    }

    const onNewRecruitsClick = ({ recruiter, users }) => {
        let layerID = `new_recruits_${recruiter.user_id}_${moment()}`;
        utils.layer.open({
            id: layerID,
            Component: ResultsList.bind(this, {
                layerID: layerID,
                items: users,
                onClick: onUserClick,
                onRenderItem: onRenderUserItem,
                onRenderHeader: onRenderUserItem.bind(this, recruiter, 0, [recruiter], true),
                title: `New Recruits for ${recruiter.full_name}`
            })
        });
    }

    const onNewIncentivesTrackingReportClick = async evt => {
        try {

            // create new trip target
            let target = IncentivesTrackingReport.new();
                    
            // determine if user is able to create reports for all dealerships
            let allDealerships = false;
            if(utils.user.get().level <= User.levels.get().admin) {
                let dealership = utils.dealership.get();
                let result = await utils.sheet.showAsync({
                    items: [{
                        key: 'current',
                        title: dealership.name,
                        style: 'default'
                    },{
                        key: 'all_dealerships',
                        title: 'All Dealerships',
                        style: 'default'
                    }],
                    message: `Do you want this report to look at the sales and recruits from ${dealership.name} or all active dealerships?`,
                    position: 'bottom',
                    target: evt && evt.target,
                    title: 'New Report'
                });
                allDealerships = result === 'all_dealerships';
            }

            // open layer to edit new participation report
            utils.layer.open({
                id: 'new_incentives_tracking_report',
                abstract: Abstract.create({
                    object: target,
                    type: 'incentives_tracking_report'
                }),
                Component: AddEditIncentivesTrackingReport.bind(this, {
                    allDealerships: allDealerships,
                    isNewTarget: true
                })
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const onIncentivesTrackingReportDateSelectorClick = evt => {

        // show option to create a new report if no report is selected
        if(!incentivesTrackingReport) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please select a report from the drop-down list on the right or create a new report if no reports are available.',
                buttons: [{
                    key: 'new',
                    title: 'New Report',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'new') {
                        onNewIncentivesTrackingReportClick();
                        return;
                    }
                }
            });
            return;
        }

        // fallback to showing options for editing date range
        utils.alert.show({
            title: 'Just a Second',
            message: 'The dates shown here were specifically selected for this report. Please edit the report date range if you need to look at other dates.',
            buttons: [{
                key: 'dates',
                title: 'Edit Report Dates',
                style: 'default',
                visible: incentivesTrackingReport ? true : false
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'dates') {
                    onEditIncentivesTrackingReportClick();
                    return;
                }
            }
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'graph',
                style: 'default',
                title: `${graphProps.current.visible ? 'Hide' : 'Show'} Graph`
            },{
                key: 'inactive',
                style: 'default',
                title: `${showInactiveUsers.current ? 'Hide' : 'Show'} Inactive Users`,
                visible: utils.user.get().level <= User.levels.get().admin
            }],
            sort: false,
            position: 'bottom',
            target: evt.target
        }, key => {
            if(key === 'graph') {
                graphProps.current.visible = !graphProps.current.visible;
                onRequestNewReport();  
                return;   
            }
            if(key === 'inactive') {
                showInactiveUsers.current = !showInactiveUsers.current;
                onRequestNewReport();
                return;
            }
        });
    }

    const onPrintReport = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let results = await Request.get(utils, '/reports/', {
                    end_date: endDate.current.format('YYYY-MM-DD'),
                    graph_props: graphProps.current,
                    one_to_one: oneToOne.current,
                    start_date: startDate.current.format('YYYY-MM-DD'),
                    type: panelID,
                    ...props
                });
                if(!results || results.length === 0) {
                    throw new Error('No results are available to print for this report');
                }
                setLoading(false);
                resolve(results);

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onRemoveIncentivesTrackingReportClick = evt => {
        evt.stopPropagation();
        utils.alert.show({
            title: `Remove "${incentivesTrackingReport.name}"`,
            message: 'Are you sure that you want to remove this report? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveIncentivesTrackingReportConfirm();
                    return;
                }
            }
        })
    }

    const onRemoveIncentivesTrackingReportConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.5);
            await Request.post(utils, '/resources/', {
                id: incentivesTrackingReport.id,
                target_dealership_id: incentivesTrackingReport.target_dealership_id,
                type: 'delete_incentives_tracking_report'
            });

            // remove trip from local state
            setResults([]);
            setPaging(null);
            setIncentivesTrackingReport(trip => trip.id === incentivesTrackingReport.id ? null : trip);
            setIncentivesTrackingReports(trips => trips.filter(trip => trip.id !== incentivesTrackingReport.id));

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${incentivesTrackingReport.name}" incentives tracking report has been removed`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing the requested report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onResultClick = result => {
        switch(panelID) {
            case 'all_recruits':
            onAllRecruitsClick(result);
            break;

            case 'new_recruits':
            case 'new_recruits_with_dealers':
            if(result.users) {
                onNewRecruitsClick(result);
            }
            break;

            case 'top_recruiting_offices':
            if(result.users) {
                onTopRecruitingOfficeClick(result);
            }
            break;


            case 'dealer_personal_sales':
            case 'director_inside_office':
            case 'personal_sales':
            case 'safety_advisor_personal_sales':
            case 'thirty_for_thirty':
            if(result.user) {
                onUserClick(result.user.user_id);
            }
            break;

            case 'incentives_tracking':
            onUserClick(result.user_id);
            break;

            case 'dealership_sales':
            case 'vested_director_sales':
            if(result.dealership) {
                onDealershipClick(result.dealership.id);
            }
            break;
        }
    }

    const onRenderCardItem = (card, index, cards) => (
        Views.entry({
            key: index,
            title: card.getCustomerNames(),
            subTitle: Utils.formatProtectionDate(card.date),
            icon: { path: 'images/cards-icon-clear-small.png' },
            badge: getTotalUnitsBadge(card),
            firstItem: index === 0,
            singleItem: cards.length === 1,
            lastItem: index === cards.length - 1,
            bottomBorder: index !== cards.length - 1,
            onClick: onCardClick.bind(this, card)
        })
    )

    const onRenderDealershipItem = (dealership, index, dealerships, details = false) => (
        Views.entry({
            key: index,
            title: dealership.name,
            subTitle: dealership.dealer ? dealership.dealer.full_name : 'No dealer found',
            hideIcon: true,
            badge: dealership.total_units && getTotalUnitsBadge({ total_units: dealership.total_units }),
            singleItem: dealerships.length === 1,
            firstItem: index === 0,
            lastItem: index === dealerships.length - 1,
            bottomBorder: index !== dealerships.length - 1,
            onClick: details ? onDealershipClick.bind(this, dealership.id) : onShowDealershipUsers.bind(this, dealership)
        })
    )

    const onRenderRecruitItem = (user, index, users, details = false) => (
        Views.entry({
            badge: [{
                color: Appearance.colors.secondary(),
                text: `${user.recruits} ${user.recruits === 1 ? 'Recruit' : 'Recruits'}`
            },{
                color: Appearance.colors.grey(),
                text: user.active === false && 'Inactive'
            }],
            bottomBorder: index !== users.length - 1,
            icon: { path: user.avatar },
            key: index,
            onClick: details ? onUserClick.bind(this, user.user_id) : onBreakdownClick.bind(this, {
                id: user.user_id,
                type: 'users',
                title: `New Recruits from ${user.full_name}`,
                onRenderHeader: onRenderUserItem.bind(this, user, 0, [user]),
                onRenderItem: onRenderUserItem
            }),
            subTitle: user.email_address || 'No email address provided',
            title: user.full_name
        })
    )

    const onRenderSectorItem = (sector, index, sectors) => (
        Views.entry({
            bottomBorder: index !== sectors.length - 1,
            hideIcon: true,
            key: index,
            onClick: onSectorClick.bind(this, sector),
            subTitle: sector.director ? sector.director.full_name : 'No director found',
            title: sector.name
        })
    )

    const onRenderUserItem = (user, index, users, details = false) => {

        let badges = user.total_units && [getTotalUnitsBadge({ total_units: user.total_units })] || [];
        if(user.active === false) {
            badges.push({
                color: Appearance.colors.grey(),
                text: 'Inactive'
            });
        }
        return (
            Views.entry({
                badge: badges,
                bottomBorder: index !== users.length - 1,
                icon: { path: user.avatar },
                key: index,
                onClick: details ? onUserClick.bind(this, user.user_id) : onShowUserProtections.bind(this, user),
                subTitle: user.email_address || 'No email address provided',
                title: user.full_name
            })
        )
    }

    const onRequestNewReport = () => {

        // prevent mvoing forward if dates have not been initialized
        if(!endDate.current || !startDate.current) {
            console.warn('waiting on date range...');
            return;
        }
        
        // prevent multiple requests from firing while limit slider is dragging
        if(typeof(graphProps.current.onUpdateLimit) === 'function') {
            console.warn('slider is dragging...');
            return;
        }

        // fetch contents of report if panel is not meant for incentives tracking reports
        fetchReport();
    }

    const onSectorClick = async target => {
        try {
            let sector = await Sector.get(utils, target.id, target.type);
            utils.layer.open({
                id: `sector_details_${sector.id}`,
                abstract: Abstract.create({
                    type: 'sector',
                    object: sector
                }),
                Component: SectorDetails
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this team. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onShowBreakdownResults = props => {

        let { id, onRenderHeader, onRenderItem, paging, results, title, type } = props;
        let layerID = `breakdown_${type}_${id}`;

        utils.layer.open({
            id: layerID,
            Component: ResultsList.bind(this, {
                layerID: layerID,
                items: results,
                limit: limit,
                onFetch: fetchBreakdown,
                onRenderHeader: (...args) => onRenderHeader(...args, true),
                onRenderItem: onRenderItem,
                paging: paging,
                props: props,
                title: `Breakdown for ${title}`
            })
        });
    }

    const onShowDealershipUsers = dealership => {
        onBreakdownClick({
            id: dealership.id,
            type: 'users',
            title: `Protections from ${dealership.name}`,
            onRenderHeader: onRenderDealershipItem.bind(this, dealership, 0, [dealership], true),
            onRenderItem: onRenderUserItem
        });
    }

    const onShowUserProtections = user => {
        onBreakdownClick({
            id: user.user_id,
            type: 'cards',
            title: `Protections from ${user.full_name}`,
            onRenderHeader: onRenderUserItem.bind(this, user, 0, [user], true),
            onRenderItem: onRenderCardItem
        });
    }

    const onTopRecruitingOfficeClick = target => {
        let layerID = `top_recruiting_offices_${target.id}_${moment()}`;
        utils.layer.open({
            id: layerID,
            Component: ResultsList.bind(this, {
                layerID: layerID,
                items: target.users,
                onRenderHeader: onRenderDealershipItem.bind(this, target, 0, [target]),
                onRenderItem: onRenderUserItem,
                title: `New Recruits for ${target.name}`,
                type: 'dealerships'
            })
        });
    }

    const onUpdateDateRange = props => {

        // update refs for report request
        endDate.current = props.detail.end;
        startDate.current = props.detail.start;

        // update state for date fields and request report fetch
        //setDates({ end: endDate.current, start: startDate.current });
        onRequestNewReport();
    }

    const onUpdateSliderValue = value => {
        try {
            if(graphProps.current.onUpdateLimit) {
                clearTimeout(graphProps.current.onUpdateLimit);
            }
            graphProps.current.limit = value;
            graphProps.current.onUpdateLimit = setTimeout(() => {
                graphProps.current.onUpdateLimit = null;
                onRequestNewReport();
            }, 1000);

        } catch(e) {
            console.log(e.message);
        }
    }

    const onUserClick = async userID => {
        try {
            let user = await User.get(utils, userID);
            utils.layer.open({
                id: `user_details_${user.user_id}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this user. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const hasRightColumn = () => {
        return graphProps.current.visible;
    }

    const hasValidResults = () => {
        return !results || results.length === 0 || (panelID === 'incentives_tracking' && !incentivesTrackingReport) ? false : true;
    }

    const shouldPadRow = () => {
        return [ 'all_recruits', 'dealer_personal_sales', 'director_inside_office', 'new_recruits', 'new_recruits_with_dealers', 'incentives_tracking', 'personal_sales', 'safety_advisor_personal_sales', 'top_recruiting_offices', 'thirty_for_thirty' ].includes(panelID) ? false : true;
    }

    const onEmbededResultsSelection = async () => {
        try {

            // fetch un-paged list of results from server
            setLoading(true);
            let results = await Request.get(utils, '/reports/', {
                ...getRequestProps(),
                limit: 9999,
                paging: false
            });

            // notify subscribers that data has changed
            setLoading(false);
            if(typeof(onDataChange) === 'function') {

                // format results based on the report that produced them
                switch(id) {
                    case 'dealer_personal_sales':
                    case 'safety_advisor_personal_sales':
                    case 'thirty_for_thirty':
                    onDataChange(results.map(result => result.user));
                    break;

                    case 'dealership_sales':
                    case 'director_inside_office':
                    onDataChange(results.map(result => result.dealership));
                    break;

                    case 'division_team_sales':
                    case 'region_team_sales':
                    onDataChange(results.map(result => result.sector));
                    break;

                    case 'new_recruits':
                    case 'new_recruits_with_dealers':
                    onDataChange(results.map(result => result.recruiter));
                    break;

                    case 'top_recruiting_offices':
                    onDataChange(results);
                    break;
                }
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of results for this report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        if(embeded) {
            return [{
                key: 'confirm',
                onClick: onEmbededResultsSelection,
                style: 'default',
                title: 'Use These Results'
            }]
        }
        return [{
            color: 'grey',
            key: 'options',
            onClick: onOptionsClick,
            title: 'Options'
        }];
    }

    const getContent = () => {
        if(graphProps.current.visible && graphData) {
            let { datasets } = graphData;
            return (
                <div
                className={`${hasRightColumn() ? 'col-12 col-md-8 col-lg-9' : 'col-12'} p-0`}
                style={{
                    overflow: 'hidden'
                }}>
                    <Bar
                    width={500}
                    height={150}
                    data={{
                        datasets: datasets.map(dataset => ({
                            data: dataset.data,
                            label: dataset.label,
                            borderWidth: 2,
                            backgroundColor: ({ datasetIndex }) => {
                                return datasetIndex % 2 === 0 ? Appearance.colors.primary() : Appearance.colors.secondary();
                            },
                            borderColor: ({ datasetIndex }) => {
                                let color = datasetIndex % 2 === 0 ? Appearance.colors.primary() : Appearance.colors.secondary();
                                return Utils.hexToRGBA(color, 0.25);
                            }
                        }))
                    }}
                    options={{
                        title: { display: false },
                        legend: { display: false },
                        responsive: true,
                        maintainAspectRatio: true,
                        tooltips: {
                            callbacks: {
                                title: ({ datasetIndex }) => graphData.labels[datasetIndex]
                            }
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 20
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: value => Utils.softNumberFormat(value)
                                }
                            }]
                        }
                    }} />
                </div>
            )
        }
        if(hasValidResults() === false) {
            return (
                <div
                className={`${hasRightColumn() ? 'col-12 col-md-9 col-lg-10' : 'col-12'} p-0`}
                style={{
                    border: `1px solid ${Appearance.colors.divider()}`,
                    borderRadius: 10,
                    overflow: 'hidden'
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        bottomBorder: false,
                        subTitle: 'No results were found for this report',
                        title: 'No Results Found'
                    })}
                </div>
            )
        }
        return (
            <div
            className={`${hasRightColumn() ? 'col-12 col-md-9 col-lg-10' : 'col-12'} p-0`}
            style={{
                border: `1px solid ${Appearance.colors.divider()}`,
                borderRadius: 10,
                overflow: 'hidden'
            }}>
                <table
                className={`${shouldPadRow() ? 'px-3 py-2' : 'p-0'} m-0`}
                style={{
                    width: '100%',
                    tableLayout: 'fixed'
                }}>
                    <thead style={{
                        width: '100%'
                    }}>
                        {getFields()}
                    </thead>
                    <tbody style={{
                        width: '100%'
                    }}>
                        {results.map((result, index) => {
                            return getFields(result, index)
                        })}
                    </tbody>
                    <tfoot style={{
                        width: '100%'
                    }}>
                        {getFooter()}
                    </tfoot>
                </table>
            </div>
        )
    }

    const getContentTargets = () => {
        switch(panelID) {
            case 'area_team_sales':
            case 'division_team_sales':
            case 'region_team_sales':
            return 'sector';

            case 'dealership_sales':
            case 'top_recruiting_offices':
            case 'vested_director_sales':
            return 'dealership';

            case 'all_recruits':
            case 'dealer_personal_sales':
            case 'director_inside_office':
            case 'new_recruits':
            case 'new_recruits_with_dealers':
            case 'personal_sales':
            case 'safety_advisor_personal_sales':
            case 'thirty_for_thirty':
            return 'user';

            case 'incentives_tracking':
            return ['card', 'incentives_tracking_report'];

            default:
            return 'Unknown';
        }
    }

    const getDateChangeEventIdentifier = () => {
        return embeded ? 'embeded_report_date_range_change' : 'report_date_range_change';
    }

    const getDateSelector = () => {
        if(panelID === 'thirty_for_thirty') {
            return (
                <MonthYearPicker
                value={moment().startOf('month')}
                style={{
                    maxWidth: 300
                }}
                onChange={({ end, start }) => {

                    // update date values
                    endDate.current = end;
                    startDate.current = start;

                    // emit change event for 30 for 30 if applicable
                    if(panelID !== 'thirty_for_thirty') {
                        utils.events.emit(getDateChangeEventIdentifier(), {
                            end: end,
                            start: start
                        });
                    }

                    // request report fetch
                    onRequestNewReport();
                }} />
            )
        }

        if(panelID === 'incentives_tracking') {
            return (
                <DualDatePickerField
                onDisabledClick={onIncentivesTrackingReportDateSelectorClick}
                selectedStartDate={incentivesTrackingReport && incentivesTrackingReport.preferences.start_date}
                selectedEndDate={incentivesTrackingReport && incentivesTrackingReport.preferences.end_date} 
                utils={utils}/>
            )
        }

        return (
            <DualDatePickerField
            utils={utils}
            selectedStartDate={startDate.current}
            selectedEndDate={endDate.current}
            onStartDateChange={date => {

                // update end date value
                startDate.current = date;

                // request new report fetch
                onRequestNewReport();

                // notify event subscribers of data change
                if(panelID !== 'thirty_for_thirty') {
                    utils.events.emit(getDateChangeEventIdentifier(), {
                        end: endDate.current,
                        start: date
                    });
                }
            }}
            onEndDateChange={date => {

                // update end date value
                endDate.current = date;

                // request new report fetch
                onRequestNewReport();

                // notify event subscribers of data change
                if(panelID !== 'thirty_for_thirty') {
                    utils.events.emit(getDateChangeEventIdentifier(), {
                        end: date,
                        start: startDate.current
                    });
                }
                
            }} />
        )
    }

    const getFields = (result, index) => {

        let fields = [];
        let target = result || {};
        switch(panelID) {
            case 'all_recruits':
            fields = [{
                key: 'user',
                title: 'Recruiter',
                value: (
                    Views.entry({
                        title: `${target.first_name} ${target.last_name}`,
                        subTitle: `Recruited ${moment(target.recruit_date).format('MMMM Do, YYYY')}`,
                        icon: {
                            path: target.avatar
                        },
                        bottomBorder: false
                    })
                )
            },{
                key: 'dealership',
                title: 'Dealership',
                value: target.dealership ? target.dealership.name : 'Dealership not available'
            },{
                key: 'qualified_recruits',
                title: 'Qualified Recruits',
                value: getValueContainer(target.qualified_recruit_count)
            },{
                key: 'recruits',
                title: 'Total Recruits',
                value: getValueContainer(target.recruit_count)
            }];
            break;

            case 'area_team_sales':
            case 'division_team_sales':
            case 'region_team_sales':
            fields = [{
                key: 'name',
                title: `${sector} Name`,
                value: target.sector ? target.sector.name : null
            },{
                key: 'director',
                title: 'Director',
                value: target.director ? target.director.full_name : 'Director not available'
            },{
                key: 'cards',
                title: 'Protections',
                value: getValueContainer(target.cards, Appearance.colors.green)
            },{
                key: 'total_units',
                title: 'Total Units',
                value: getValueContainer(target.total_units, Appearance.colors.primary(), onBreakdownClick.bind(this, target.sector && {
                    id: target.sector.id,
                    type: 'dealerships',
                    title: `Protections from ${target.sector.name}`,
                    onRenderItem: onRenderDealershipItem,
                    onRenderHeader: onRenderSectorItem.bind(this, {
                        ...target.sector,
                        director: target.director
                    }, 0, [target.sector])
                }))
            }];
            break;

            case 'dealer_personal_sales':
            fields = [{
                key: 'user',
                title: 'First and Last Name',
                value: (
                    Views.entry({
                        badge: [{
                            color: Appearance.colors.grey(),
                            text: target.user?.active === false && 'Inactive'
                        }],
                        bottomBorder: false,
                        icon: {
                            path: target.user?.avatar
                        },
                        subTitle: target.user?.email_address || 'No email address provided',
                        title: target.user?.full_name || 'Name not available'
                    })
                )
            },{
                key: 'dealership',
                title: 'Dealership',
                value: target.dealership || 'Dealership not available'
            },{
                key: 'cards',
                title: 'Protections',
                value: getValueContainer(target.cards, Appearance.colors.green)
            },{
                key: 'total_units',
                title: 'Total Units',
                value: getValueContainer(target.total_units, Appearance.colors.primary(), onBreakdownClick.bind(this, target.user && {
                    id: target.user.user_id,
                    type: 'cards',
                    title: `Protections from ${target.user.full_name}`,
                    onRenderHeader: onRenderUserItem.bind(this, target.user, 0, [target.user]),
                    onRenderItem: onRenderCardItem
                }))
            }];
            break;

            case 'dealership_sales':
            fields = [{
                key: 'dealership',
                title: 'Dealership',
                value: target.dealership ? target.dealership.name : null
            },{
                key: 'dealer',
                title: 'Dealer',
                value: target.dealer ? target.dealer.full_name : 'Dealer not available'
            },{
                key: 'level',
                title: 'Account Type',
                value: target.dealer ? User.levels.toText(target.dealer.level) : null
            },{
                key: 'cards',
                title: 'Protections',
                value: getValueContainer(target.cards, Appearance.colors.green)
            },{
                key: 'total_units',
                title: 'Total Units',
                value: getValueContainer(target.total_units, Appearance.colors.primary(), onBreakdownClick.bind(this, target.dealership && {
                    id: target.dealership.id,
                    type: 'users',
                    title: `Protections from ${target.dealership.name}`,
                    onRenderHeader: onRenderDealershipItem.bind(this, target.dealership, 0, [target.dealership]),
                    onRenderItem: onRenderUserItem
                }))
            }];
            break;

            case 'director_inside_office':
            fields = [{
                key: 'user',
                title: 'First and Last Name',
                value: (
                    Views.entry({
                        title: target.user ? target.user.full_name : null,
                        subTitle: target.user ? (target.user.email_address || 'No email address provided') : null,
                        icon: {
                            path: target.user ? target.user.avatar : null
                        },
                        bottomBorder: false
                    })
                )
            },{
                key: 'level',
                title: 'Account Type',
                value: target.user ? User.levels.toText(target.user.level) : null
            },{
                key: 'dealership',
                title: 'Dealership',
                value: target.dealership ? target.dealership.name : 'Dealership not available'
            },{
                key: 'cards',
                title: 'Protections',
                value: getValueContainer(target.cards, Appearance.colors.green)
            },{
                key: 'total_units',
                title: 'Total Units',
                value: getValueContainer(target.total_units, Appearance.colors.primary(), onBreakdownClick.bind(this, target.user && {
                    id: target.dealership.id,
                    type: 'users',
                    title: `Protections from ${target.user.full_name}`,
                    onRenderHeader: onRenderDealershipItem.bind(this, target.dealership, 0, [target.dealership]),
                    onRenderItem: onRenderUserItem
                }))
            }];
            break;

            case 'new_recruits':
            case 'new_recruits_with_dealers':
            fields = [{
                key: 'user',
                title: 'Recruiter',
                value: (
                    Views.entry({
                        title: target.recruiter ? target.recruiter.full_name : null,
                        subTitle: target.recruiter ? (target.recruiter.email_address || 'No email address provided') : null,
                        icon: {
                            path: target.recruiter ? target.recruiter.avatar : null
                        },
                        bottomBorder: false
                    })
                )
            },{
                key: 'level',
                title: 'Account Type',
                value: target.recruiter ? User.levels.toText(target.recruiter.level) : null
            },{
                key: 'dealership',
                title: 'Dealership',
                value: target.dealership ? target.dealership.name : 'Dealership not available'
            },{
                key: 'total_units',
                title: 'Total Units',
                value: getValueContainer(target.total_units)
            },{
                key: 'recruits',
                title: 'Recruits',
                value: target.users ? getValueContainer(target.users.length, Appearance.colors.secondary()) : null
            }];
            break;

            case 'incentives_tracking':
            fields = [{
                key: 'user',
                title: 'User',
                value: (
                    Views.entry({
                        title: target.sold_by_user ? `${target.sold_by_user.first_name} ${target.sold_by_user.last_name}` : 'Name not available',
                        subTitle: target.sold_by_user ? target.sold_by_user.dealership_name : 'Dealership name not available',
                        icon: {
                            path: target.sold_by_user && target.sold_by_user.avatar
                        },
                        bottomBorder: false
                    })
                )
            },{
                key: 'units',
                title: 'Total Units',
                value: target.total_units || '0'
            },{
                key: 'recruits',
                title: 'Recruits',
                value: target.recruits || '0'
            },{
                key: 'score',
                title: 'Score',
                value: target.score || '0'
            }].concat(getIncentivesTrackingReportTargets(target));
            break;

            case 'personal_sales':
            case 'safety_advisor_personal_sales':
            fields = [{
                key: 'user',
                title: 'First and Last Name',
                value: (
                    Views.entry({
                        badge: [{
                            color: Appearance.colors.secondary(),
                            text: target.users?.length > 0 && `${target.users.length} Merged Accounts`
                        },{
                            color: Appearance.colors.grey(),
                            text: target.user?.active === false && 'Inactive'
                        }],
                        bottomBorder: false,
                        icon: {
                            path: target.user?.avatar
                        },
                        subTitle: target.user?.email_address || 'No email address provided',
                        title: target.user?.full_name || 'Name not available'
                    })
                )
            },{
                key: 'dealership',
                title: 'Dealership',
                value: target.dealership || 'Dealership not available'
            },{
                key: 'dealer',
                title: 'Dealer',
                value: target.dealer || 'Dealer not available'
            },{
                key: 'cards',
                title: 'Protections',
                value: getValueContainer(target.cards, Appearance.colors.green),
            },{
                key: 'total_units',
                title: 'Total Units',
                value: getValueContainer(target.total_units)
            }];
            break;

            case 'top_recruiting_offices':
            fields = [{
                key: 'dealership',
                title: 'Dealership',
                value: (
                    Views.entry({
                        title: target.name,
                        subTitle: target.dealer ? target.dealer.full_name : 'Dealer name not available',
                        icon: {
                            path: target.dealer ? target.dealer.avatar : null
                        },
                        bottomBorder: false
                    })
                )
            },{
                key: 'level',
                title: 'Account Type',
                value: target.dealer ? User.levels.toText(target.dealer.level) : null
            },{
                key: 'dealer',
                title: 'Dealer',
                value: target.dealer ? target.dealer.full_name : null
            },{
                key: 'total_units',
                title: 'Total Units',
                value: getValueContainer(target.total_units, Appearance.colors.primary(), onBreakdownClick.bind(this, {
                    id: target.id,
                    type: 'dealership_users',
                    title: `Protections from ${target.name}`,
                    onRenderHeader: onRenderDealershipItem.bind(this, target, 0, [target]),
                    onRenderItem: onRenderUserItem
                }))
            },{
                key: 'recruits',
                title: 'Recruits',
                value: getValueContainer(target.users ? target.users.length : 0, Appearance.colors.secondary(), onBreakdownClick.bind(this, {
                    id: target.id,
                    type: 'recruits',
                    title: `Protections from ${target.name}`,
                    onRenderHeader: onRenderDealershipItem.bind(this, target, 0, [target]),
                    onRenderItem: onRenderRecruitItem
                }))
            }];
            break;

            case 'thirty_for_thirty':
            fields = [{
                key: 'user',
                title: 'First and Last Name',
                value: (
                    Views.entry({
                        title: target.user ? target.user.full_name : null,
                        subTitle: target.user ? (target.user.email_address || 'No email address provided') : null,
                        icon: {
                            path: target.user ? target.user.avatar : null
                        },
                        bottomBorder: false
                    })
                )
            },{
                key: 'level',
                title: 'Account Type',
                value: target.user ? User.levels.toText(target.user.level) : null
            },{
                key: 'dealership',
                title: 'Dealership',
                value: target.dealership ? target.dealership.name : null
            },{
                key: 'total',
                title: 'Total Demos',
                value: getValueContainer(target.total)
            }];
            break;

            case 'vested_director_sales':
            fields = [{
                key: 'dealership',
                title: 'Dealership',
                value: target.dealership ? target.dealership.name : null
            },{
                key: 'dealer',
                title: 'Dealer',
                value: target.dealer ? target.dealer.full_name : 'Dealer not available'
            },{
                key: 'level',
                title: 'Account Type',
                value: target.dealer ? User.levels.toText(target.dealer.level) : null
            },{
                key: 'total_units',
                title: 'Total Units',
                value: getValueContainer(target.total_units)
            }];
            break;
        }

        if(!result) {
            return (
                <tr style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                    {fields.map((field, index) => {
                        return (
                            <td
                            key={index}
                            className={`${index === 0 ? 'px-3' : 'px-0'} py-2 text-button flexible-table-column`}>
                                <div style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.title(),
                                        flexGrow: 1
                                    }}>{field.title}</span>
                                </div>
                            </td>
                        )
                    })}
                </tr>
            )
        }

        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={`${shouldPadRow() && index === 0 ? 'px-3 py-2' : 'p-0'} m-0 text-button flexible-table-column`}
                        onClick={onResultClick.bind(this, result)}>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                ...field.style
                            }}>{field.value}</span>
                        </td>
                    )
                })}
            </tr>
        )
    }

    const getFooter = () => {
        if(!totals) {
            return null;
        }
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}>
                <td
                key={index}
                className={'px-3 py-2 flexible-table-column'}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        fontWeight: 700
                    }}>{'Total'}</span>
                </td>
                {totals.map((total, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                color: Appearance.colors.text(),
                                fontWeight: 700
                            }}>{total}</span>
                        </td>
                    )
                })}
            </tr>
        )
    }

    const getOneToOneSelector = () => {
        if(utils.dealership.restricted.get() === true || ![ 'dealer_personal_sales', 'new_recruits', 'new_recruits_with_dealers', 'personal_sales', 'safety_advisor_personal_sales', 'vested_director_sales' ].includes(panelID)) {
            return null;
        }
        return (
            <select
            className={`custom-select ${window.theme}`}
            value={oneToOne.current ? '1:1' : 'Hybrid'}
            onChange={e => {
                let id = Utils.attributeForKey.select(e, 'id');
                oneToOne.current = parseInt(id) === 1;
                onRequestNewReport();
            }}
            style={{
                width: 200
            }}>
                <option>{'Choose a category...'}</option>
                <option id={1}>{'1:1'}</option>
                <option id={0}>{'Hybrid'}</option>
            </select>
        )
    }

    const getPaging = () => {
        return graphProps.current.visible === false && {
            data: paging,
            limit: limit,
            offset: offset,
            onClick: next => {
                offset.current = next;
                onRequestNewReport();
            }
        }
    }

    const getIncentivesTrackingReportListItems = () => {
        return incentivesTrackingReports.map(trip => ({
            id: trip.id,
            title: trip.name
        }));
    }

    const getIncentivesTrackingReportTargets = user => {
        let targets = incentivesTrackingReport && incentivesTrackingReport.preferences && incentivesTrackingReport.preferences.targets || [];
        return targets.map((target, index) => ({
            key: `target_${index}`,
            title: target.name,
            value: user.score >= target.value ? 'Yes' : 'No',
            ...user.score >= target.value && {
                style: {
                    color: Appearance.colors.green,
                    fontWeight: 700
                }
            }
        }))
    }

    const getPrintProps = () => {

        // print props are not supported for embeded reports
        if(embeded) {
            return null;
        }

        let fields = [];
        let headers = [];
        let onRenderItem = null;

        switch(panelID) {
            case 'all_recruits':
            onRenderItem = (item, index, items) => ({
                user: item.recruiter ? item.recruiter.full_name : null,
                dealership: item.dealership || 'Dealership not available',
                recruits: item.users ? getValueContainer(item.users.length) : null
            });
            headers = [{
                key: 'user',
                title: 'Recruiter'
            },{
                key: 'dealership',
                title: 'Dealership'
            },{
                key: 'recruits',
                title: 'Recruits'
            }];
            break;

            case 'area_team_sales':
            case 'division_team_sales':
            case 'region_team_sales':
            onRenderItem = item => ({
                name: item.sector ? item.sector.name : null,
                director: item.director ? item.director.full_name : 'Director not available',
                cards: item.cards,
                units: item.total_units
            })
            headers = [{
                key: 'name',
                title: `${sector} Name`
            },{
                key: 'director',
                title: 'Director'
            },{
                key: 'cards',
                title: 'Protections'
            },{
                key: 'total_units',
                title: 'Units'
            }];
            break;

            case 'dealer_personal_sales':
            onRenderItem = item => ({
                user: item.user ? item.user.full_name : null,
                dealership: item.dealership,
                cards: item.cards,
                units: item.total_units
            });
            headers = [{
                key: 'user',
                title: 'User'
            },{
                key: 'dealership',
                title: 'Dealership'
            },{
                key: 'cards',
                title: 'Protections'
            },{
                key: 'units',
                title: 'Units'
            }];
            break;

            case 'dealership_sales':
            onRenderItem = item => ({
                dealership: item.dealership ? item.dealership.name : null,
                dealer: item.dealer ? item.dealer.full_name : 'Dealer not available',
                level: item.dealer ? User.levels.toText(item.dealer.level) : null,
                cards: item.cards,
                units: item.total_units
            })
            headers = [{
                key: 'dealership',
                title: 'Dealership'
            },{
                key: 'dealer',
                title: 'Dealer'
            },{
                key: 'level',
                title: 'Account Type'
            },{
                key: 'cards',
                title: 'Protections'
            },{
                key: 'units',
                title: 'Units'
            }];
            break;

            case 'director_inside_office':
            onRenderItem = item => ({
                user: item.user ? item.user.full_name : null,
                dealership: item.dealership || 'Dealership not available',
                level: item.level ? User.levels.toText(item.level) :  null,
                cards: item.cards,
                units: item.total_units
            })
            headers = [{
                key: 'user',
                title: 'First and Last Name'
            },{
                key: 'level',
                title: 'Account Type'
            },{
                key: 'dealership',
                title: 'Dealership'
            },{
                key: 'cards',
                title: 'Protections'
            },{
                key: 'total_units',
                title: 'Units'
            }];
            break;

            case 'new_recruits':
            case 'new_recruits_with_dealers':
            onRenderItem = item => ({
                dealership: item.dealership ? item.dealership.name : 'Dealership not available',
                recruits: item.users ? getValueContainer(item.users.length) : null,
                total_units: item.total_units,
                user: item.recruiter ? item.recruiter.full_name : null,
            });
            headers = [{
                key: 'user',
                title: 'Recruiter'
            },{
                key: 'dealership',
                title: 'Dealership'
            },{
                key: 'total_units',
                title: 'Total Units'
            },{
                key: 'recruits',
                title: 'Recruits'
            }];
            break;

            case 'personal_sales':
            case 'safety_advisor_personal_sales':
            onRenderItem = item => ({
                user: item.user ? item.user.full_name : null,
                dealership: item.dealership,
                dealer: item.dealer,
                cards: item.cards,
                units: item.total_units
            });
            headers = [{
                key: 'user',
                title: 'User'
            },{
                key: 'dealership',
                title: 'Dealership'
            },{
                key: 'dealer',
                title: 'Dealer'
            },{
                key: 'cards',
                title: 'Protections'
            },{
                key: 'units',
                title: 'Units'
            }];
            break;

            case 'top_recruiting_offices':
            onRenderItem = item => ({
                dealership: item.name || 'Dealership not available',
                dealer: item.dealer ? item.dealer.full_name : 'Dealer not available',
                level: item.dealer ? User.levels.toText(item.dealer.level) : null,
                total_units: item.total_units,
                recruits: item.users ? getValueContainer(item.users.length) : null
            });
            headers = [{
                key: 'dealership',
                title: 'Dealership'
            },{
                key: 'dealer',
                title: 'Dealer'
            },{
                key: 'level',
                title: 'Account Type'
            },{
                key: 'total_units',
                title: 'Total Units'
            },{
                key: 'recruits',
                title: 'Recruits'
            }];
            break;

            case 'thirty_for_thirty':
            onRenderItem = item => ({
                user: item.user ? item.user.full_name : null,
                level: item.user ? User.levels.toText(item.user.level) : null,
                dealership: item.dealership ? item.dealership.name : null,
                total: item.total
            });
            headers = [{
                key: 'user',
                title: 'Frist and Last Name'
            },{
                key: 'level',
                title: 'Account Type'
            },{
                key: 'dealership',
                title: 'Dealership'
            },{
                key: 'total',
                title: 'Total Demos'
            }];
            break;

            case 'vested_director_sales':
            onRenderItem = item => ({
                dealership: item.dealership ? item.dealership.name : null,
                dealer: item.dealership ? item.dealership.dealer : 'Dealer not available',
                level: item.dealership ? User.levels.toText(item.dealership.dealer.level) : null,
                cards: item.cards,
                units: item.total_units
            })
            headers = [{
                key: 'dealership',
                title: 'Dealership'
            },{
                key: 'dealer',
                title: 'Dealer'
            },{
                key: 'level',
                title: 'Account Type'
            },{
                key: 'cards',
                title: 'Protections'
            },{
                key: 'units',
                title: 'Units'
            }];
            break;

            default:
            return null;
        }

        return {
            headers: headers,
            onFetch: onPrintReport,
            onRenderItem: onRenderItem
        }
    }

    const getRequestProps = () => ({
        end_date: endDate.current.format('YYYY-MM-DD'),
        graph_props: graphProps.current,
        include_inactive_users: showInactiveUsers.current,
        limit: limit,
        offset: offset.current,
        one_to_one: oneToOne.current,
        start_date: startDate.current.format('YYYY-MM-DD'),
        type: panelID,
        ...id === 'incentives_tracking' && {
            trip_id: incentivesTrackingReport.id
        },
        ...embeded && {
            restrict_to_dealership: false
        }
    })

    const getIncentivesTrackingReportSelector = () => {
        if(id === 'incentives_tracking') {
            let items = getIncentivesTrackingReportListItems();
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}>
                    <ListField
                    items={items}
                    onChange={onIncentivesTrackingReportChange}
                    placeholder={'Select a report from below...'}
                    value={items.find(item => incentivesTrackingReport && incentivesTrackingReport.id === item.id)}
                    containerStyle={{
                        minWidth: 250
                    }}/>
                    {incentivesTrackingReport && (
                        <>
                        <img
                        className={'text-button'}
                        onClick={onEditIncentivesTrackingReportClick}
                        src={'images/settings-button-grey.png'}
                        title={'Edit'}
                        style={{
                            height: 25,
                            marginLeft: 8,
                            minWidth: 25,
                            width: 25
                        }} />
                        <img
                        className={'text-button'}
                        onClick={onRemoveIncentivesTrackingReportClick}
                        src={'images/red-x-icon.png'}
                        title={'Delete'}
                        style={{
                            height: 25,
                            marginLeft: 4,
                            minWidth: 25,
                            width: 25
                        }} />
                        </>
                    )}
                    <img
                    className={'text-button'}
                    onClick={onNewIncentivesTrackingReportClick}
                    src={'images/plus-button-blue-small.png'}
                    title={'New Report'}
                    style={{
                        height: 25,
                        marginLeft: incentivesTrackingReport ? 4 : 8,
                        minWidth: 25,
                        width: 25
                    }} />
                </div>
            )
        }
    }

    const getTitle = () => {
        switch(panelID) {
            case 'all_recruits':
            return 'All Recruits';

            case 'area_team_sales':
            return 'Area Team Sales';

            case 'dealer_personal_sales':
            return 'Dealer Personal Sales';

            case 'dealership_sales':
            return 'Dealership Sales';

            case 'director_inside_office':
            return 'Director Inside Office';

            case 'division_team_sales':
            return 'Division Team Sales';

            case 'new_recruits':
            return 'New Recruits';

            case 'new_recruits_with_dealers':
            return 'New Recruits (Dealers Included)';

            case 'incentives_tracking':
            return 'Incentives Tracking';

            case 'personal_sales':
            return 'Personal Sales';

            case 'region_team_sales':
            return 'Region Team Sales';

            case 'safety_advisor_personal_sales':
            return 'Safety Advisor Personal Sales';

            case 'thirty_for_thirty':
            return '30 for 30 Demos';

            case 'top_recruiting_offices':
            return 'Top Recruiting Offices';

            case 'vested_director_sales':
            return 'Vested Director Sales';

            default:
            return 'Unknown';
        }
    }

    const getVisibilitySelectorProps = () => {
        if(['division_team_sales', 'incentives_tracking', 'region_team_sales'].includes(panelID) === true) {
            return null;
        }
        return embeded !== true && {
            onVisibilityChange: () => {
                setIncentivesTrackingReport(null);
                onRequestNewReport();
            } 
        }
    }

    const fetchBreakdown = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/reports/', {
                    ...getRequestProps(),
                    limit: props.limit,
                    offset: props.offset,
                    breakdown_props: {
                        id: props.id,
                        type: props.type
                    }
                });
                switch(props.type) {
                    case 'dealership_users':
                    case 'users':
                    resolve({
                        ...response,
                        results: response.results.map(result => {
                            let user = User.create(result.user);
                            user.total_units = result.total_units;
                            return user;
                        })
                    });
                    break;

                    case 'dealerships':
                    resolve({
                        ...response,
                        results: response.results.map(result => {
                            let dealership = Dealership.create(result.dealership);
                            dealership.total_units = result.total_units;
                            return dealership;
                        })
                    });
                    break;

                    case 'recruits':
                    resolve({
                        ...response,
                        results: response.results.map(result => {
                            let user = User.create(result.user);
                            user.recruits = result.recruits;
                            return user;
                        })
                    });
                    break;

                    default:
                    resolve({
                        ...response,
                        results: response.results.map(result => Card.create(result))
                    });
                }

            } catch(e) {
                reject(e);
            }
        });
    }

    const fetchIncentivesTrackingReports = async () => {
        try {
            if(id !== 'incentives_tracking') {
                return;
            }
            setLoading(true);
            let { trips } = await Request.get(utils, '/resources/', {
                target_dealership_id: utils.dealership.restricted.get() || utils.user.get().level > User.levels.get().admin ? utils.dealership.get().id : null,
                type: 'incentives_tracking_reports'
            });

            setLoading(false);
            let targets = trips.map(trip => IncentivesTrackingReport.create(trip));
            setIncentivesTrackingReports(targets);
            setIncentivesTrackingReport(trip => trip || targets[0]);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of incentives tracking reports. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchReport = async () => {
        try {

            // prevent moving forward if a trip has not been selected for the incentives tracking report report
            if(id === 'incentives_tracking' && !incentivesTrackingReport) {
                return;
            }

            // send request to server
            setLoading(true);
            let { datasets, labels, paging, results, totals } = await Request.get(utils, '/reports/', getRequestProps());

            // end loading and set graph data props if applicable
            setLoading(false);
            if(graphProps.current.visible) {
                setGraphData({
                    labels: labels,
                    datasets: datasets
                });
                return;
            }

            // set standard report results and paging if applicable
            setPaging(paging);
            setTotals(totals);
            setResults(results);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ${getTitle().toLowerCase()} report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupDates = () => {

        // set default values for end and start date
        switch(default_range) {
            case 'week':
            endDate.current = moment().startOf('week').add(7, 'days');
            startDate.current = moment().startOf('week').add(1, 'days');
            break;

            case 'month':
            endDate.current = moment();
            startDate.current = moment().startOf('month');
            break;

            case 'ytd':
            endDate.current = Utils.ytd.end_date();
            startDate.current = Utils.ytd.start_date();
            break;

            default:
            if(panelID === 'thirty_for_thirty') {
                endDate.current = moment().endOf('month');
                startDate.current = moment().startOf('month');
                return;
            }
            endDate.current = Utils.ytd.end_date();
            startDate.current = Utils.ytd.start_date();
        }

        // submit request to fetch report
        onRequestNewReport();
    }

    useEffect(() => {
        offset.current = 0;
        onRequestNewReport();
    }, [incentivesTrackingReport]);

    useEffect(() => {

        // setup default dates and fetch incentives tracking reports list
        setupDates();
        fetchIncentivesTrackingReports();

        // register event handler for dealership changes and date changes from other reports
        utils.events.on(panelID, 'dealership_change', onDealershipChange);
        utils.events.on(panelID, getDateChangeEventIdentifier(), onUpdateDateRange);

        // subscribe to target changes
        utils.content.subscribe(panelID, getContentTargets(), {
            onFetch: () => {
                fetchReport();
                fetchIncentivesTrackingReports();
            },
            onUpdate: () => {
                fetchReport();
                fetchIncentivesTrackingReports();
            }
        });

        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchReport);
            utils.events.off(panelID, getDateChangeEventIdentifier(), onUpdateDateRange);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={getTitle()}
        index={index}
        utils={utils}
        options={{
            ...options,
            embeded: embeded,
            loading: loading,
            print: getPrintProps(),
            buttons: getButtons(),
            paging: getPaging(),
            ...getVisibilitySelectorProps()
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: Utils.isMobile() ? 'column' : 'row',
                justifyContent: 'space-between',
                marginBottom: 8
            }}>
                {getDateSelector()}
                {getOneToOneSelector()}
                {getIncentivesTrackingReportSelector()}
            </div>
            <div className={'row p-0 m-0'}>
                {getContent()}
                {graphProps.visible && (
                    <div
                    className={'col-12 col-md-4 col-lg-3 p-0 pl-md-2'}
                    style={{
                        alignItems: 'flex-start',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <div style={{
                            ...Appearance.styles.unstyledPanel(),
                            maxWidth: 335,
                            padding: '8px 12px 8px 12px',
                            width: '100%'
                        }}>
                            <LayerItem
                            title={'Limit Results'}
                            childrenStyle={{
                                paddingTop: 0
                            }}
                            style={{
                                marginBottom: 0
                            }}>
                                <StatefulSlider
                                step={1}
                                min={5}
                                max={100}
                                defaultValue={25}
                                showCounter={true}
                                onChange={onUpdateSliderValue} />
                            </LayerItem>
                        </div>
                    </div>
                )}
            </div>
        </Panel>
    )
}

export const TeamsFlowChart = ({ index, options, utils }) => {

    const panelID = 'teams_flow_chart';
    const flowContainer = useRef(null);
    const nodeWidth = 172;
    const nodeHeight = 36;

    const [elements, setElements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);

    const onConnect = props => setElements((els) => {
        return addEdge({ ...props, type: 'smoothstep', animated: true }, els);
    });

    const onElementClick = (evt, element) => {
        console.log(evt, element);
    }

    const onElementsRemove = elementsToRemove => {
        return setElements((els) => removeElements(elementsToRemove, els));
    }

    const onLayout = useCallback(direction => {
        const layoutedElements = getLayoutedElements(elements, direction);
        setElements(layoutedElements);
    }, [elements]);

    const onFormatElements = elements => {
        return elements.map((entry, index) => {
            if(!entry.element) {
                return entry
            }
            return {
                ...entry,
                data: {
                    element: entry.element,
                    label: (
                        Views.entry({
                            title: entry.element.name,
                            subTitle: entry.element.sub_text,
                            bottomBorder: false,
                            icon: {
                                path: entry.element.avatar,
                                imageStyle: {
                                    boxShadow: null
                                }
                            },
                            style: {
                                padding: 0,
                                textAlign: 'left'
                            }
                        })
                    )
                }
            }
        })
    }

    const getLayoutedElements = (elements, direction = 'TB') => {
        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction });

        elements.forEach((el) => {
            if (isNode(el)) {
                dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
            } else {
                dagreGraph.setEdge(el.source, el.target);
            }
        });

        dagre.layout(dagreGraph);

        return elements.map((el) => {
            if (isNode(el)) {
                const nodeWithPosition = dagreGraph.node(el.id);
                el.targetPosition = isHorizontal ? 'left' : 'top';
                el.sourcePosition = isHorizontal ? 'right' : 'bottom';

                // unfortunately we need this little hack to pass a slightly different position
                // to notify react flow about the change. Moreover we are shifting the dagre node position
                // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
                el.position = {
                    x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
                    y: nodeWithPosition.y - nodeHeight / 2,
                };
            }
            return el;
        });
    };

    const fetchTeams = async () => {
        try {
            setLoading(true);
            let { teams } = await Request.get(utils, '/sectors/', {
                type: 'flow_nodes',
                width: width,
                height: height
            });

            console.log(teams);
            setLoading(false);
            let elements = getLayoutedElements(onFormatElements(teams));
            setElements(elements);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the teams list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(flowContainer.current) {
            setHeight(flowContainer.current.clientHeight);
            setWidth(flowContainer.current.clientWidth);
        }
    }, [flowContainer.current])

    useEffect(() => {
        if(width && height) {
            fetchTeams();
        }
    }, [height, width]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchTeams);
        return () => {
            utils.events.off(panelID, 'dealership_change', fetchTeams);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Teams Overview'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading
        }}>
            <div
            ref={flowContainer}
            style={{
                width: '100%',
                height: 500
            }}>
                <ReactFlowProvider>
                    <ReactFlow
                    connectionLineType={'smoothstep'}
                    elements={elements}
                    elementsSelectable={true}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    paneMoveable={true}
                    onConnect={onConnect}
                    onElementsRemove={onElementsRemove}
                    onElementClick={onElementClick} />
                </ReactFlowProvider>
            </div>
        </Panel>
    )
}