import moment from 'moment-timezone';

import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

class CommLinkClass {

    access_token = null;
    active = null;
    address = null;
    alexa_setup_code = null;
    connection_status = null;
    date = null;
    dealership = null;
    dealeship_phone_number = null;
    default = false;
    firmware_version = null;
    flags = [];
    guid = null;
    id = null;
    image = null;
    install_date = null;
    location = null;
    guid = null;
    name = null;
    online = null;
    security_key = null;
    sensor = null;
    serial_number = null;
    sold_by_user = null;
    stats = [];

    constructor() {
        return this;
    }

    close = () => {
        let target = this.edits || this;
        this.address = target.address;
        this.dealership = target.dealership;
        this.dealeship_phone_number = target.dealeship_phone_number;
        this.location = target.location;
        this.name = target.name;
        this.sold_by_user = target.sold_by_user;
        return this;
    }

    create = (props = {}) => {
        this.access_token = props.access_token;
        this.active = props.active;
        this.address = props.address;
        this.alexa_setup_code = props.alexa_setup_code;
        this.connection_status = props.connection_status;
        this.date = props.date && moment.utc(props.date).local();
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.dealeship_phone_number = props.dealeship_phone_number;
        this.firmware_version = props.firmware_version;
        this.flags = props.flags || [];
        this.guid = props.guid;
        this.id = props.id;
        this.image = props.image && { uri: props.image };
        this.install_date = props.install_date && moment.utc(props.install_date).local();
        this.location = props.location;
        this.name = props.name;
        this.online = formatStatusProps(props.online);
        this.security_key = props.security_key;
        this.sensor = props.sensor;
        this.serial_number = props.serial_number;
        this.sold_by_user = props.sold_by_user && User.create(props.sold_by_user);
        this.stats = props.stats;
        return this;
    }

    open = () => {
        this.edits = {
            address: this.address,
            dealership: this.dealership,
            dealeship_phone_number: this.dealeship_phone_number,
            location: this.location,
            name: this.name,
            sold_by_user: this.sold_by_user
        }
        return this.edits;
    }

    refresh = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { comm_link } = await Request.get(utils, '/omnishield/', {
                    type: 'comm_link_details',
                    comm_link_guid: this.guid
                });
                this.create(comm_link);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    toJSON = props => {
        let target = props || this;
        return {
            address: target.address,
            dealership_id: target.dealership && target.dealership.id,
            dealeship_phone_number: target.dealeship_phone_number,
            id: this.id,
            location: target.location,
            name: target.name,
            sold_by_user_id: target.sold_by_user && target.sold_by_user.user_id
        }
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let  { location } = await Request.put(utils, '/omnishield/', {
                    type: 'update_comm_link',
                    comm_link_guid: this.guid,
                    ...this.toJSON(this.edits)
                });

                // close edits and set formatted location variable 
                this.close();
                this.location = location;

                // notify subscribers of data change
                utils.content.update({
                    type: 'comm_link',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

class ContactClass {

    address = null;
    avatar = null;
    comm_link_id = null;
    date = null;
    email_address = null;
    first_name = null;
    full_name = null;
    id = null;
    include_with_travel = true;
    last_name = null;
    local_ids = [];
    location = null;
    owner = null;
    phone_number = null;
    preferences = null;
    resident = false;
    
    constructor() {
        return this;
    }

    apply = (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                this.open();
                this.set(props);
                await this.update(utils, { avatar: props.avatar });
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    close = () => {
        let target = this.edits || this;
        this.address = target.address;
        this.email_address = target.email_address;
        this.first_name = target.first_name;
        this.full_name = target.full_name;
        this.include_with_travel = target.include_with_travel;
        this.last_name = target.last_name;
        this.local_ids = target.local_ids;
        this.location = target.location;
        this.owner = target.owner;
        this.phone_number = target.phone_number;
        this.preferences = target.preferences;
        this.resident = target.resident;
        return this;
    }

    formatAddresses = addresses => {
        return addresses.map(addr => Utils.formatAddress(addr, { remove_abbreviations: true }));
    }

    create = (props = {}) => {

        this.address = props.address;
        this.avatar = props.avatar;
        this.comm_link_id = props.comm_link_id;
        this.date = props.date && moment(props.date);
        this.email_address = props.email_address;
        this.first_name = props.first_name;
        this.full_name = props.full_name;
        this.id = props.id;
        this.include_with_travel = props.include_with_travel;
        this.last_name = props.last_name;
        this.local_ids = props.local_ids || [];
        this.location = props.location;
        this.owner = props.owner;
        this.phone_number = props.phone_number;
        this.preferences = props.preferences || { emergency_alerts: true };
        this.resident = props.resident;
        return this;
    }
    
    getPreference = (key, edits = false) => {
        let target = edits ? this.edits : this;
        return target.preferences && target.preferences[key];
    }

    getSharedFunctions = () => ({
        getPreference: key => this.getPreference(key, true)
    });

    open = () => {
        this.edits = {
            ...this.getSharedFunctions(),
            address: this.address,
            comm_link_id: this.comm_link_id,
            email_address: this.email_address,
            first_name: this.first_name,
            full_name: this.full_name,
            include_with_travel: this.include_with_travel,
            last_name: this.last_name,
            location: this.location,
            local_ids: this.local_ids,
            owner: this.owner,
            phone_number: this.phone_number,
            preferences: this.preferences || { emergency_alerts: true },
            resident: this.resident || false
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.getSharedFunctions(),
            ...this.edits,
            ...props,
            preferences: {
                ...this.edits.preferences,
                ...props.preferences
            }
        }
        this.edits.full_name = `${this.edits.first_name} ${this.edits.last_name}`;
        return this.edits;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { address, id } = await Request.post(utils, '/omnishield/', {
                    type: 'new_contact',
                    ...this.toJSON(this.edits),
                    ...props
                });

                // close object edits
                this.close();

                // update local variables with server generated variables
                this.address = address;
                this.id = id;

                // notify subscribers that new content is available
                utils.content.fetch('comm_link_contact');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            address: target.address,
            comm_link_id: target.comm_link_id,
            email_address: target.email_address,
            first_name: target.first_name,
            full_name: target.full_name,
            id: this.id,
            include_with_travel: target.include_with_travel,
            last_name: target.last_name,
            local_ids: this.local_ids,
            location: target.location,
            owner: target.owner,
            phone_number: target.phone_number,
            preferences: target.preferences,
            resident: target.resident
        }
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { address } = await Request.put(utils, '/omnishield/', {
                    type: 'update_contact',
                    id: this.id,
                    ...this.toJSON(this.edits),
                    ...props
                });

                // close object edits
                this.close();

                // update local variables with server generated variables
                this.address = address;

                // notify subscribers that a content update is available
                utils.content.update({
                    type: 'contacts',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

class SensorClass {

    active = null;
    comm_link_id = null;
    comm_link_serial_number = null; // for regulated sensors only
    date = null;
    id = null;
    location = null;
    notifications = null;
    serial_number = null;
    status = null;
    supports_verbose_data = true;
    type = null;
    verboseUnixTimestamp = null;
    version = null;

    constructor() {
        return this;
    }

    canReplace = () => {

        // sensors that are no longer active are no eligibile for replacement
        if(this.active === false) {
            return false;
        }

        // sensors that are already being replaced can not be replaced again 
        if([ sensorStatusCodes.replacement_requested, sensorStatusCodes.replacement_in_progress, sensorStatusCodes.replacement_completed ].includes(this.status.code) === true) {
            return false;
        }

        // sensors that are no longer in service can not be replaced
        if(this.status === sensorStatusCodes.out_of_service) {
            return false;
        }
        return true;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.comm_link_id = props.comm_link_id;
        this.comm_link_serial_number = props.comm_link_serial_number;
        this.date = props.date && moment.utc(props.date).local();
        this.id = props.id;
        this.location = props.location;
        this.notifications = props.notifications;
        this.serial_number = props.serial_number;
        this.status = props.status;
        this.supports_verbose_data = props.supports_verbose_data;
        this.type = props.type;
        this.verbose = props.verbose;
        this.version = props.version;
        return this;
    }

    get verbose() {
        return this.verbose_data;
    }
    set verbose(val) {
        this.verbose_data = val && {
            ...val,
            unix_timestamp: val.date ? moment.utc(val.date).local().unix() : -1
        };
    }

    get = key => {
        if(!this.verbose || this.verbose[key] === null || this.verbose[key] === undefined) {
             return null;
        }
        switch(key) {
            case 'date':
            case 'last_activation_date':
            case 'last_self_test':
            return moment.utc(this.verbose[key]).local();

            default:
            return this.verbose[key];
        }
    }

    supportsVerboseData = () => {
        if(this.type.code === sensorTypes.comm_link) {
            return false;
        }
        return this.verbose || this.supports_verbose_data === true ? true : false;
    }
}

const sensorStatusCodes = {
    in_service: 1,
    out_of_service: 2,
    rapidly_updating: 3,
    high_volume_sms: 4,
    replacement_requested: 5,
    replacement_in_progress: 6,
    replacement_completed: 7,
    serial_number_collision: 8,
    booster_needed: 9
}

const sensorStatusToText = code => {
    switch(code) {
        case sensorStatusCodes.in_service:
        return 'In Service';

        case sensorStatusCodes.out_of_service:
        return 'Out of Service';

        case sensorStatusCodes.rapidly_updating:
        return 'Rapidly Updating';

        case sensorStatusCodes.high_volume_sms:
        return 'High Volume SMS';

        case sensorStatusCodes.replacement_requested:
        return 'Replacement Requested';

        case sensorStatusCodes.replacement_in_progress:
        return 'Replacement In-Progress';

        case sensorStatusCodes.replacement_completed:
        return 'Replacement Completed';

        case sensorStatusCodes.serial_number_collision:
        return 'Serial Number Collision';

        case sensorStatusCodes.booster_needed:
        return 'Booster Needed';

        default:
        return 'Unknown status code';
    }
}

const sensorTypes = {
    comm_link: 1,
    smoke: 2,
    ac_smoke: 3,
    heat: 4,
    co: 5,
    bed_shaker: 6,
    water: 7,
    ble_link: 8,
    water_valve: 9,
    smoke_co: 10,
    ac_smoke_co: 11
}

const sensorTypeToText = type => {
    switch(type) {
        case sensorTypes.ac_smoke:
        return 'AC Smoke Sensor';

        case sensorTypes.ac_smoke_co:
        return 'AC Smoke/CO Sensor';

        case sensorTypes.bed_shaker:
        return 'Bed Shaker';

        case sensorTypes.co:
        return 'Carbon Monoxide Sensor';

        case sensorTypes.comm_link:
        return 'Comm Link';

        case sensorTypes.heat:
        return 'Heat Sensor';

        case sensorTypes.smoke:
        return 'Smoke Sensor';

        case sensorTypes.smoke_co:
        return 'Smoke/CO Sensor';

        case sensorTypes.water:
        return 'Water Sensor';

        default:
        return 'Unknown sensor';
    }
}

const fetchCommLink = async (utils, guid, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { comm_link } = await Request.get(utils, '/omnishield/', {
                type: 'comm_link_details',
                comm_link_guid: guid,
                ...props
            });
            resolve(new CommLinkClass().create(comm_link));

        } catch(e) {
            reject(e);
        }
    })
}

const fetchSensor = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { sensor } = await Request.get(utils, '/omnishield/', {
                ...props,
                type: 'sensor_details'
            });
            if(!sensor) {
                throw new Error('Unable to locate sensor using the supplied identifiers');
            }
            resolve(new SensorClass().create(sensor));

        } catch(e) {
            reject(e);
        }
    })
}

const formatStatusProps = props => {

    // declare general disclaimer
    let disclaimer = 'This tool is for diagnostic purposes only and is not intended as a fail safe status indicator for the system.';

    // prevent moving forward if no status entry was provided or if a date for the most recent update is not available
    if(!props || !props.date) {
        return {
            ...props,
            message: `This network has not communicated with us recently. Please check that the Comm Link has a valid internet connection. ${disclaimer}`
        };
    }

    // prepare relative date of last update
    let date = moment.utc(props.date).local();

    // return offline message if at least an hour has passed since the last verbose update
    if(moment() > moment(date).add(1, 'hours')) {

        // update status flah and format date as a friendly human-readable string
        props.status = false;
        date = Utils.formatDate(date);

        // remove 'Today' from date string and show just time if applicable
        if(date.includes('Today')) {
            date = moment.utc(props.date).local().format('h:mma');
        }

        // set date string to lowercase if string starts with 'Yesterday'
        // other date formats start with a day of the week or month and need to stay capitalized
        if(date.includes('Yesterday')) {
            date = date.toLowerCase();
        }
        return {
            ...props,
            message: `This network has not communicated with us since ${date}. Please check that the Comm Link has a valid internet connection. ${disclaimer}`
        };
    }

    // return online message if comm link has received an update within the server-defined window
    props.status = true;
    return {
        ...props,
        message: `This Comm Link was last seen around ${date.format('h:mma')}. This network may show that it is offline if we do not receive a response at least once every hour. ${disclaimer}`
    };
}

export default {
    new: () => new CommLinkClass(),
    get: fetchCommLink,
    create: props => new CommLinkClass().create(props),
    formatStatusProps,
    Contact: {
        new: () => new ContactClass(),
        create: props => new ContactClass().create(props),
    },
    Sensor: {
        get: fetchSensor,
        new: () => new SensorClass(),
        create: props => new SensorClass().create(props),
        status: {
            get: () => sensorStatusCodes,
            toText: sensorStatusToText
        },
        types: {
            get: () => sensorTypes,
            toText: sensorTypeToText
        }
    }
};
