import React, { useEffect, useRef, useState } from 'react';

import { animated, useSpring } from '@react-spring/web';
import { getTotalUnitsBadge } from 'managers/Cards.js';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Card from 'classes/Card.js';
import { CardDetails } from 'managers/Cards.js';
import CommLink from 'classes/CommLink.js';
import { CommLinkDetails, CommLinkSensorDetails } from 'managers/OmniShield.js';
import Dealership from 'classes/Dealership.js';
import { DealershipDetails } from 'managers/Dealerships.js';
import ListField from 'views/ListField.js';
import Request from 'files/Request.js';
import Sector from 'classes/Sector.js';
import { SectorDetails } from 'managers/Sectors.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';
import PageControl from './PageControl';

const Searching = ({ onClose, user, utils, visible }) => {

    const limit = 10;

    const category = useRef(user && user.level === User.levels.get().exigent_admin ? 'comm_links' : 'cards');
    const controller = useRef(null);
    const field = useRef(null);
    const offset = useRef(0);
    
    const [background, setBackground] = useSpring(() => ({
        opacity: 0,
        config: { mass: 1, tension: 180, friction: 22 }
    }));
    const [container, setContainer] = useSpring(() => ({
        opacity: 0,
        transform: 'scale(0)',
        config: { mass: 1, tension: 180, friction: 16 }
    }));
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [shouldShowContent, setShouldShowContent] = useState(false);

    const onCategoryChange = item => {

        // clear results and update category selection
        setResults([]);
        category.current = item.id;
        offset.current = 0;

        // run search again if search text is available
        if(searchText) {
            setLoading(true);
            fetchResults();
        }
    }

    const onHelpButtonClick = () => {
        utils.alert.show({
            title: `About ${utils.user.get().level <= User.levels.get().admin ? 'Global Search' : 'Search'}`,
            message: `${utils.user.get().level <= User.levels.get().admin ? 'Global Search' : 'Search'} helps you locate customers, comm links, protections, user accounts, and much more all from one unified place. For quicker access, you can press Command F or Control F on your keyboard to open the search window.`
        });
    }

    const onKeydown = evt => {
        if(evt.keyCode === 27 && typeof(onClose) === 'function') {
            onClose();
        }
    }

    const onResultClick = async item => {
        try {

            // notify subscribers that a close event has occurred
            if(typeof(onClose) === 'function') {
                onClose();
            }

            // determine which type of details layer to open based on the currently selected category
            await Utils.sleep(0.5);
            switch(category.current) {
                case 'cards':
                utils.layer.open({
                    id: `card_details_${item.id}`,
                    abstract: Abstract.create({
                        type: 'card',
                        object: item
                    }),
                    Component: CardDetails
                })
                break;

                case 'comm_links':
                utils.layer.open({
                    id: `comm_link_details_${item.id}`,
                    abstract: Abstract.create({
                        type: 'comm_link',
                        object: item
                    }),
                    Component: CommLinkDetails
                });
                break;

                case 'dealerships':
                utils.layer.open({
                    id: `dealership_details_${item.id}`,
                    abstract: Abstract.create({
                        type: 'dealership',
                        object: item
                    }),
                    Component: DealershipDetails
                });
                break;

                case 'divisions':
                case 'regions':
                utils.layer.open({
                    id: `sector_details_${item.id}`,
                    abstract: Abstract.create({
                        type: 'sector',
                        object: item
                    }),
                    Component: SectorDetails
                });
                break;

                case 'sensors':
                utils.layer.open({
                    id: `comm_link_sensor_details_${item.id}`,
                    abstract: Abstract.create({
                        type: 'comm_link_sensor',
                        object: item
                    }),
                    Component: CommLinkSensorDetails
                });
                break;

                case 'users':
                utils.layer.open({
                    id: `user_details_${item.user_id}`,
                    abstract: Abstract.create({
                        type: 'user',
                        object: item
                    }),
                    Component: UserDetails
                });
                break;

                default:
                return null;
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setSearchText(text);
    }

    const onVisibilityChange = async () => {
        try {
            if(visible === true) {
                setShouldShowContent(true);
            }
            setBackground({ opacity: visible ? 1 : 0 });
            setContainer({
                opacity: visible ? 1 : 0,
                transform: `scale(${visible ? 1 : 0})`
            })
            if(visible === false) {
                await Utils.sleep(0.5);
                setShouldShowContent(false);
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const getCategoryItems = () => {

        // prepare default category items
        let items = [{
            id: 'comm_links',
            title: 'Comm Links'
        },{
            id: 'dealerships',
            title: 'Dealerships'
        },{
            id: 'divisions',
            title: 'Divisions'
        },{
            id: 'cards',
            title: 'Protections'
        },{
            id: 'regions',
            title: 'Regions'
        },{
            id: 'sensors',
            title: 'Sensors'
        },{
            id: 'users',
            title: 'Users'
        }];

        // filter out category items based on account type
        switch(utils.user.get().level) {
            case User.levels.get().admin:
            case User.levels.get().region_director:
            return items;

            case User.levels.get().exigent_admin:
            return [{
                id: 'comm_links',
                title: 'Comm Links'
            },{
                id: 'sensors',
                title: 'Sensors'
            }];

            case User.levels.get().division_director:
            return [{
                id: 'comm_links',
                title: 'Comm Links'
            },{
                id: 'cards',
                title: 'Protections'
            },{
                id: 'sensors',
                title: 'Sensors'
            },{
                id: 'users',
                title: 'Users'
            }];

            default:
            return [{
                id: 'comm_links',
                title: 'Comm Links'
            },{
                id: 'cards',
                title: 'Protections'
            },{
                id: 'sensors',
                title: 'Sensors'
            },{
                id: 'users',
                title: 'Users'
            }];
        }
    }

    const getCategoryValue = () => {
        let selected = category.current && getCategoryItems().find(item => item.id === category.current);
        return selected && selected.title;
    }

    const getContent = () => {
        if(shouldShowContent === false) {
            return null;
        }
        return (
            <div style={{
                alignItems: 'center',
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                left: 0,
                position: 'fixed',
                right: 0,
                top: 0,
                zIndex: 9900
            }}>
                <animated.div
                onClick={onClose}
                style={{
                    backgroundColor: Appearance.colors.dim,
                    bottom: 0,
                    left: 0,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    ...background
                }} />
                <animated.div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 20,
                    width: 500,
                    ...container
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <span style={{
                                color: Appearance.colors.text(),
                                fontSize: 18,
                                fontWeight: 800,
                                lineHeight: 1,
                                marginBottom: 6
                            }}>{utils.user.get().level <= User.levels.get().admin ? 'Global Search' : 'Search'}</span>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                color: Appearance.colors.subText(),
                                lineHeight: 1,
                                marginBottom: 16
                            }}>{'Start typing below to begin searching...'}</span>
                        </div>
                        <img
                        className={'text-button'}
                        src={'images/help-button-grey.png'}
                        onClick={onHelpButtonClick}
                        style={{
                            height: 20,
                            marginLeft: 12,
                            objectFit: 'contain',
                            width: 20
                        }} />
                    </div>
                    <div 
                    className={'row p-0 m-0'}
                    style={{
                        width: '100%'
                    }}>
                        <div className={'col-12 col-lg-7 col-xl-8 p-0'}>
                            <TextField
                            canClearField={true}
                            icon={'search'}
                            loading={loading === true}
                            onChange={onSearchTextChange} 
                            onChangeStart={setLoading.bind(this, true)}
                            ref={field}
                            useDelay={true}
                            value={searchText}/>
                        </div>
                        <div className={'col-12 col-lg-5 col-xl-4 pt-2 pb-0 px-0 pt-lg-0 pl-lg-2'}>
                            <ListField
                            items={getCategoryItems()}
                            onChange={onCategoryChange}
                            value={getCategoryValue()} />
                        </div>
                    </div>

                    {getResults()}
                </animated.div>
            </div>
        )
    }

    const getResults = () => {

        // show loading content if loading is in progress
        if(!searchText || loading === true) {
            return null;
        }

        // prevent moving forward if no results are found
        if(!results || results.length === 0) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 16,
                    width: '100%'
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        subTitle: 'Try searching for something else or change your search category',
                        title: 'No Results Found'
                    })}
                </div>
            )
        }

        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginTop: 16
            }}>
                <div
                className={'custom-scrollbars'} 
                style={{
                    maxHeight: 'calc(100vh - 250px)',
                    overflowX: 'hidden',
                    overflowY: 'auto'
                }}>
                    {results.map((item, index, items) => {
                        switch(category.current) {
                            case 'cards':
                            return (
                                Views.entry({
                                    key: index,
                                    title: item.getCustomerNames(),
                                    subTitle: Utils.formatDate(item.date),
                                    icon: {
                                        path: 'images/cards-icon-clear-small.png'
                                    },
                                    badge: getTotalUnitsBadge(item),
                                    firstItem: index === 0,
                                    singleItem: items.length === 1,
                                    lastItem: index === items.length - 1,
                                    bottomBorder: index !== items.length - 1,
                                    onClick: onResultClick.bind(this, item)
                                })
                            )

                            case 'comm_links':
                            return (
                                Views.entry({
                                    key: index,
                                    title: item.name || `Unnamed Network ${item.serial_number}`,
                                    subTitle: `Registered: ${Utils.formatDate(item.date)}`,
                                    badge: {
                                        text: `SN ${item.serial_number}`,
                                        color: Appearance.colors.grey()
                                    },
                                    hideIcon: true,
                                    firstItem: index === 0,
                                    singleItem: items.length === 1,
                                    lastItem: index === items.length - 1,
                                    bottomBorder: index !== items.length - 1,
                                    onClick: onResultClick.bind(this, item)
                                })
                            )

                            case 'dealerships':
                            return (
                                Views.entry({
                                    key: index,
                                    title: item.name,
                                    subTitle: item.dealer ? item.dealer.full_name : null,
                                    hideIcon: true,
                                    badge: {
                                        text: item.active ? null : 'Not Active',
                                        color: Appearance.colors.grey()
                                    },
                                    firstItem: index === 0,
                                    singleItem: items.length === 1,
                                    lastItem: index === items.length - 1,
                                    bottomBorder: index !== items.length - 1,
                                    onClick: onResultClick.bind(this, item)
                                })
                            )

                            case 'divisions':
                            case 'regions':
                            return (
                                Views.entry({
                                    key: index,
                                    title: item.name,
                                    subTitle: item.director ? item.director.full_name : 'Director not available',
                                    icon: {
                                        path: item.director.avatar
                                    },
                                    badge: {
                                        text: item.active ? null : 'Not Active',
                                        color: Appearance.colors.grey()
                                    },
                                    firstItem: index === 0,
                                    singleItem: items.length === 1,
                                    lastItem: index === items.length - 1,
                                    bottomBorder: index !== items.length - 1,
                                    onClick: onResultClick.bind(this, item)
                                })
                            )

                            case 'sensors':
                            return (
                                Views.entry({
                                    key: index,
                                    title: item.location || 'Unnamed Location',
                                    subTitle: item.get('date') ? `Updated ${Utils.formatDuration(item.get('date'))}` : 'Waiting for update...',
                                    badge: {
                                        text: `SN ${item.serial_number}`,
                                        color: Appearance.colors.grey()
                                    },
                                    hideIcon: true,
                                    firstItem: index === 0,
                                    singleItem: items.length === 1,
                                    lastItem: index === items.length - 1,
                                    bottomBorder: index !== items.length - 1,
                                    onClick: onResultClick.bind(this, item)
                                })
                            )

                            case 'users':
                            return (
                                Views.entry({
                                    key: index,
                                    title: item.full_name,
                                    subTitle: item.dealership ? item.dealership.name : 'Dealership not available',
                                    icon: {
                                        path: item.avatar
                                    },
                                    badge: [{
                                        text: User.levels.toText(item.level),
                                        color: Appearance.colors.primary()
                                    },{
                                        text: item.active ? null : 'Not Active',
                                        color: Appearance.colors.grey()
                                    }],
                                    firstItem: index === 0,
                                    singleItem: items.length === 1,
                                    lastItem: index === items.length - 1,
                                    bottomBorder: index !== items.length - 1,
                                    onClick: onResultClick.bind(this, item)
                                })
                            )

                            default:
                            return null;
                        }
                    })}
                </div>
                {paging && (
                    <PageControl
                    data={paging}
                    limit={limit}
                    loading={loading === 'paging'}
                    offset={offset.current}
                    onClick={next => {
                        offset.current = next;
                        setLoading('paging');
                        fetchResults();
                    }} />
                )}
            </div>
        )
    }

    const fetchResults = async () => {
        try {

            // set loading flag
            setLoading(true);

            // determine if a previous search needs to be aborted
            if(controller.current) {
                controller.current.abort();
            }

            // create new signal controller
            controller.current = new AbortController();

            // clear results if no text or text less than 2 characters long is found
            if(!searchText || searchText.length < 2) {
                setResults(null);
                setLoading(false);
                return;
            }

            // start loading and send request to server
            let { paging, results } = await Request.get(utils, '/resources/', {
                category: category.current,
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'global_search'
            },{
                signal: controller.current.signal
            });

            // end loading, update paging state, and invalidate abort controller
            controller.current = null;
            setLoading(false);
            setPaging(paging);

            // determine how to format results using the category key
            switch(category.current) {
                case 'cards':
                setResults(results && results.map(result => Card.create(result)));
                break;

                case 'comm_links':
                setResults(results && results.map(result => CommLink.create(result.comm_link)));
                break;

                case 'dealerships':
                setResults(results && results.map(result => Dealership.create(result)));
                break;

                case 'divisions':
                case 'regions':
                setResults(results && results.map(result => Sector.create(result)));
                break;

                case 'sensors':
                setResults(results && results.map(result => CommLink.Sensor.create(result)));
                break;

                case 'users':
                setResults(results && results.map(result => User.create(result)));
                break;
            }
            

        } catch(e) {

            // determine if error was thrown from a manually aborted fetch request
            if(e.name === 'AbortError') {
                return;
            }
            
            // show error alert for request or api generated errors
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue performing your search. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchResults();
    }, [searchText]);

    useEffect(() => {
        onVisibilityChange();
    }, [visible]);

    useEffect(() => {
        if(field.current) {
            field.current.focus();
        }
    }, [field.current]);

    useEffect(() => {
        window.addEventListener('keydown', onKeydown);
        return () => {
            window.removeEventListener('keydown', onKeydown);
        }
    }, [])

    return getContent();
}

export default Searching
